<template >
  <div name="dynamicimage">
    <img
      :src="link"
      :alt="name"
      class="gallery-image"
      ref="reference"
      :title="name"
      @click="showModal = true"
    />

    <transition name="modalwindow" v-if="showModal == true">
      <div
        class="modal-mask fixed top-0 left-0 w-full h-full outline-none fade"
      >
        <div
          class="
            modal-container
            w-9/12
            my-10
            mx-auto
            rounded
            shadow-lg
            z-50
            h-full
          "
        >
          <div class="pt-2">
            <slot name="header">
              <i
                class="
                  fas
                  fa-times-circle
                  cursor-pointer
                  fixed
                  right-0
                  items-center
                  mt-4
                  mr-32
                  text-white text-4xl
                  z-50
                "
                @click="showModal = false"
              ></i>
              <h2 class="text-2xl m-3 absolute text-center text-white">
                {{ name }}
              </h2>
            </slot>
          </div>
          <div class="h-screen overflow-y-auto">
            <slot name="body">
              <img
                :src="link"
                :alt="name"
                class="gallery-image"
                ref="reference"
                :title="name"
              />
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

  <script>
import { fileResourceMixin } from "@/mixins/file-ResourceMixin.js";

export default {
  name: "dynamicimage",
   mixins: [fileResourceMixin],
  props: {
    url: { type: String, required: true },
    reference: { type: String, required: false },
    name: { type: String, required: false },
  },
  computed: {},
  data: function () {
    return {
      link: "",
      showModal: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      setTimeout(() => {
        this.getDiagram(this.url)
          .then((f) => {
            this.link = f;
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    },

  },
};
</script>

<style scoped>
.v-lazy-image {
  filter: blur(6px);
  transition: filter 0.5s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  image-orientation: from-image !important;
  cursor: pointer;
}

.modal-container {
  width: 85%;
}
</style>